.flight-date-calendar table {
  width: 100%;
}

.flight-date-calendar .calendar-wrapper {
  width: 280px;
  margin: 0 auto;
}

.flight-date-calendar .heading {
  text-align: center;
  background-color: #f15925;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.flight-date-calendar .heading .first {
  background-color: #fff;
}

.flight-date-calendar .heading .month {
  position: relative;
  display: inline-block;
  padding: 7px;
}

.flight-date-calendar .weekday {
  background-color: #ccc;
}

.flight-date-calendar .weekday td {
  width: 35px;
  text-align: center;
  font-weight: 400;
  padding: 5px 0;
}

.flight-date-calendar .weekday td.first {
  background-color: #fff;
}

.flight-date-calendar .days .day {
  width: 35px;
  text-align: center;
  background-color: #ddd;
  padding: 3px 0;
  color: #222;
  font-weight: 200;
}

.flight-date-calendar .days .day.select {
  background-color: #e4e4e4;
  cursor: pointer;
}

.flight-date-calendar .days .day.out-of-range {
  background-color: #fff;
}

.flight-date-calendar .days .day.out-of-month {
  color: #c4c4c4;
  background-color: #dddddd;
}

.flight-date-calendar .days .day.inactive {
  color: #555;
}

.flight-date-calendar .days .day.active {
  background-color: #f15925;
  color: #fff;
  font-weight: 500;
}

.flight-date-calendar .days.selected td {
  background-color: rgba(0, 128, 0, .3) !important;
}

.flight-date-calendar .days.unselected td i{
  color: #d4d4d4;
}

.flight-date-calendar .days .day.weeknum{
  color: #777;
}

.flight-date-calendar .days.selected td.day.out-of-range{
  background-color: rgba(0, 128, 0, .1) !important;
}

.flight-date-calendar .days td.day.out-of-range.out-of-month{
  background-color: #fff !important;
}

.flight-date-calendar .days .day.select.out-of-range{
  cursor: default;
}

.form_wizard .ms-hover a{
  cursor: default;
}
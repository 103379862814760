.form_wizard {
  .navbar .container {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .custom-tabs li a:hover {
    color: #f15925 !important;
  }

  .custom-tabs li.tab-current a:hover {
    color: #2b2b2b !important;
  }

  .wizard-finish {
    color: #fff !important;
  }

  .tabs-style-linemove nav a {
    padding: 5px 0;
  }

  .custom-tabs li a {
    -webkit-transition: background .5s;
    transition: background .5s;
  }
}

.calculator-container {
  background-color: #fff;
  padding: 5px;
}

.calculator-widget button {
  width: 100%;
}

.calculator-widget .output {
  background-color: rgba(241, 89, 37, 0.7);
  color: #222;
  text-align: right;
  font-size: 24px;
  height: 34px;
}

.calculator-widget .buttons-container {
  background-color: #ddd;
}
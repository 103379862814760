// Helper classes for JavaScript
// --------------------------------------------------


// Visual
// -------------------------

.cropper-invisible {
  opacity: 0;
}

.cropper-bg {
  background-image: data-uri("../images/bg.png");
}


// Visibility
// -------------------------

.cropper-hide {
  position: absolute;

  display: block;

  width: 0;
  height: 0;
}

.cropper-hidden {
  display: none !important;
}


// Cursors
// -------------------------

.cropper-move {
  cursor: move;
}

.cropper-crop {
  cursor: crosshair;
}

.cropper-disabled {
  .cropper-drag-box,
  .cropper-face,
  .cropper-line,
  .cropper-point {
    cursor: not-allowed;
  }
}

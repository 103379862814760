.brand-product-container .input-group-addon {
  border: none;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px solid rgba(120, 130, 140, 0.65);
}

#brands-products-form .input-group {
  width: 100%;
}

#brands-products-form .input-group-addon {
  border-top: none;
  border-right: none;
  background: transparent;
  width: 40px;
}

#brands-products-form .input-group-addon {
  cursor: pointer;
}
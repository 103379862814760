.user-pro-body {
  color: #2b2b2b !important;
  height: 110px !important;
  border-bottom: 1px solid #a7aeb4;
}

ul.sidebar-menu-list {
  list-style: none;
  margin: 0;
  padding: 0 0 0 20px;
}

ul.sidebar-menu-list li {
  padding: 7px 0 7px 7px;
  border-bottom: 1px solid #f6f6f6;
  width: 100%;
}

ul.sidebar-sub-menu-list {
  list-style: none;
  margin: 0;
  padding: 0 0 0 20px;
}

ul.sidebar-sub-menu-list li {
  padding: 14px 0 7px 7px;
  border-bottom: 1px solid #f6f6f6;
  width: 100%;
}

.sidebar-menu a {
  width: 100%;
}

.sidebar-menu h3 {
  text-transform: uppercase;
  font-size: 15px;
  margin: 0 0 0 10px;
  font-weight: 500;
  border-bottom: 1px solid #f6f6f6;
}

.togglediv #togglebtn i {
  margin-top: 0 !important;
}

.menu-title {
  border-bottom: 1px solid #a7aeb4;
}

.sidebarmenu .header-link:hover {
  color: #f15925;
}

.sidebarmenu .header-with-icon {
  padding-right: 30px;
  height: 31px;
  margin-bottom: -20px;
}

.sidebarmenu span.header-icon {
  position: relative;
  top: -5px;
}

.metismenu .has-arrow:after {
  border-color: #2b2b2b;
}

.sidebar-nav {
  background: #f6f6f6;
}

.sidebar-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  /*background: #f6f6f6;*/
}

.sidebar-nav .metismenu {
  background: #f6f6f6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.sidebar-nav .metismenu li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  margin: 0 !important;
}

.sidebar-nav .metismenu a {
  position: relative;
  display: block;
  padding: 3.5px 10px 3px;
  color: #2b2b2b;
  background-color: #f6f6f6;
  outline-width: 0;
  transition: none;
  border-bottom: 1px solid #a7aeb4;
  border-left: 1px solid #a7aeb4;
}

.sidebar-nav .metismenu ul a {
  padding: 3.5px 15px 3px 10px;
  margin-left: 5px;
  width: calc(100% - 5px);
  border-left: 1px solid #a7aeb4;
}

.sidebar-nav .metismenu ul {
  background-color: white;
}

.sidebar-nav .metismenu ul ul a {
  padding: 3.5px 15px 3px 10px;
  margin-left: 10px;
  width: calc(100% - 10px);
  border-left: 1px solid #a7aeb4;
}

.sidebar-nav .metismenu ul ul a {
  padding: 3.5px 15px 3px 10px;
  margin-left: 15px;
  width: calc(100% - 15px);
  border-left: 1px solid #a7aeb4;
}

.sidebar-nav .metismenu ul ul ul a {
  padding: 3.5px 15px 3px 10px;
  margin-left: 20px;
  width: calc(100% - 20px);
  border-left: 1px solid #a7aeb4;
}

.sidebar-nav .metismenu a:hover,
.sidebar-nav .metismenu a:active {
  color: #f8f9fa;
  text-decoration: none;
  background: #f15925;
}

.menu-tooltip-container.tooltip.right {
  margin: 0;
  padding: 0;
}

.menu-tooltip-container .tooltip-arrow {
  display: none;
}

.menu-tooltip-container .tooltip-inner {
  border-radius: 0;
  background-color: #f26a3d;
  height: 54px;
  margin-top: 0px;
  text-align: center;
  font-size: 15px;
  padding-top: 17px;
  font-family: 'Poppins', sans-serif;
}

.side-mini-panel ul.mini-nav > li > a:hover, .side-mini-panel ul.mini-nav > li > a:focus,
.side-mini-panel ul.mini-nav > li.selected > a {
  background-color: #f26a3d;
  border: 0;
}

.side-mini-panel ul.mini-nav > li > a:hover i, .side-mini-panel ul.mini-nav > li.selected > a i {
  color: #fff;
}

.side-mini-panel ul.mini-nav > li > a {
  border: 0;
  transition: all 0.15s;
}

.row.bg-title {
  height: 54px;
}

.left-nav-menu {
  margin: 60px 0 30px;
  max-width: 280px !important;
}

.user-profile-container {
  padding: 0 !important;
}

.nav.navbar-top-links.navbar-left {
  min-width: 220px;
}

.user-pro-body {
  height: 95px !important;
}

.mm-panels {
  background: #fff !important;
}

.mm-panels .mm-panel {
  background: #fff !important;
}

.footer {
  position: fixed;
  padding: 5px 60px;
}

html.mm-blocking {
  overflow: auto;
}

.mm-iconpanel .mm-panel.mm-iconpanel-1 {
  left: 15px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-2 {
  left: 30px;
}

.mm-iconpanel .mm-panel.mm-iconpanel-3 {
  left: 45px;
}

.mini-nav li {
  cursor: pointer;
}

.nav-coming-soon a{
  cursor: not-allowed;
}

.nav-coming-soon .label{
  margin-top: 2px;
  border-radius: 4px;
  padding: 4px 4px 4px;
  font-size: 65%;
  float: right !important;
}
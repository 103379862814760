.ag-bootstrap {
  line-height: 1 !important;
  font-family: 'Poppins', "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
  color: #000 !important;
}

.ag-header {
  color: #f7fafc !important;
  background: #2b2b2b !important;
  border-bottom: none !important;
  font-weight: 600 !important;
  font-size: 10px;
  text-transform: uppercase;
}

.ag-bootstrap .ag-header-cell-label {
  padding: 0 2px !important;
}

.ag-tool-panel { display: none;}

.ag-header-icon {
  color: #f15925 !important;
  fill: #f15925 !important;
}

.ag-bootstrap .ag-header-cell-menu-button {
  padding: 0 !important;
  margin-top: 0 !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.ag-bootstrap .ag-header-cell-menu-button {
  margin-top: 0 !important;
}

.ag-bootstrap .ag-cell-label-container {
  margin-top: 3px !important;
}

.ag-header-group-cell.group-header {
  text-align: center;
  background: #373737;
  margin: 0 !important;
}

.ag-floating-top {
  color: #f7fafc !important;
  background: #f15925 !important;
  border-bottom: none !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.ag-bootstrap .ag-floating-top .ag-row {
  background: #f15925 !important;
  text-align: right !important;
}

.ag-floating-bottom {
  color: #f7fafc !important;
  background: #f8ac92 !important;
  border-bottom: none !important;
  font-weight: 600 !important;
  text-transform: uppercase;
}

.ag-bootstrap .ag-floating-bottom .ag-row {
  background: #f8ac92 !important;
  text-align: center !important;
}

.ag-bootstrap .ag-pinned-left-floating-bottom .ag-row .ag-cell:nth-child(2) {
  background: #f15925 !important;
  text-align: center !important;
}

.ag-bootstrap .ag-pinned-left-floating-bottom .ag-row .ag-cell:nth-child(2) div {
  margin-right: 15px !important;
}

.ag-bootstrap .ag-pinned-left-floating-bottom .ag-row .ag-cell:nth-child(2) select {
  color: #2b2b2b;
}

.ag-bootstrap .ag-cell-focus {
  border: 0 !important;
  background-color: #bbb;
}

.ag-bootstrap .ag-ltr .ag-cell-no-focus {
  border: 0 !important;
}

.ag-bootstrap .ag-row-selected {
  background-color: rgba(241, 89, 37, 0.7) !important;
}

.ag-bl-overlay {
  z-index: 2000;
}

.ag-overlay-loading-center p {
  font-size: 14px;
  line-height: 2;
  font-weight: 800;
}

.ag-dnd-ghost {
  font-size: 10px !important;
  font-family: 'Poppins', "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  position: absolute !important;
  color: #f7fafc !important;
  background: #2b2b2b !important;
  border: 1px solid #f15925 !important;
  cursor: move !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
  overflow: hidden !important;
  -o-text-overflow: ellipsis !important;
  text-overflow: ellipsis !important;
  padding: 0 !important;
  line-height: 1 !important;

}

.ag-dnd-ghost-icon {
  display: inline-block !important;
  float: left !important;
  padding-left: 0 !important;
  padding-right: 2px !important;
}

.ag-dnd-ghost-label {
  display: inline-block !important;
  text-transform: uppercase !important;
}

.ag-bootstrap .ag-ltr .ag-header-cell {
  border-right: 1px #fff solid !important;
}

.ag-bootstrap .ag-ltr .ag-header-cell:last-child {
  border-right: 0 ;
}

.ag-pinned-left-cols-viewport {
  border-right: solid #2b2b2b 1px;
}

.ag-pinned-right-cols-viewport {
  border-left: solid #2b2b2b 1px;
}

.ag-pinned-left-floating-top, .ag-pinned-left-header {
  border-right: 1px #fff solid !important;
}

.ag-pinned-right-floating-top, .ag-pinned-right-header {
  border-left: 1px #fff solid !important;
}

.ag-pinned-left-floating-bottom {
  border-right: #000 solid 1px !important;
}

.ag-pinned-right-floating-bottom {
  border-left: #000 solid 1px !important;
}

.ag-header-cell.ws-movable {
  cursor: move !important;
}

span[id="agMenu"].ag-header-icon {
  cursor: pointer !important;
  margin-top: 5px !important;
}

.ag-header-cell[colid="row_select"] span[id="agMenu"] {
  display: none !important;
}

.ag-bootstrap .ag-header-cell-label {
  margin-top: 5px !important;
}

.ag-bootstrap .ag-menu-option-active {
  background: #f8ac92 !important;
}

.fixedHeaderCellClass {
  cursor: not-allowed;
}

.ag-bootstrap .ag-cell-inline-editing input {
  border: 0;
  background-color: transparent;
}

.ag-bootstrap .ag-cell-inline-editing {
  border: solid 1px rgba(241, 89, 37, 0.35);
  background-color: rgba(241, 89, 37, 0.35) !important;
}

.grid-popup-container input{
  max-width: 75% !important;
}

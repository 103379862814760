.preloader {
  width: 100%;
  height: 100%;
  text-align: center;
}

.mainloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 119;
  background: rgba(255, 255, 255, .5);
  margin-top: 60px;
  opacity: 1 !important;
}

.mainloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

.cssload-speeding-wheel {
  width: 31px;
  height: 31px;
  margin: 0 auto;
  border: 2px solid @themecolor;
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: cssload-spin 425ms infinite linear;
  -o-animation: cssload-spin 425ms infinite linear;
  -ms-animation: cssload-spin 425ms infinite linear;
  -webkit-animation: cssload-spin 425ms infinite linear;
  -moz-animation: cssload-spin 425ms infinite linear;
}

.cssload-speeding-wheel.small {
  width: 15px;
  height: 15px;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.progressloader {
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  z-index: 119;
  background: rgba(255, 255, 255, 0.5);
  margin-top: 60px;
}

.progressloader .white-box {
  background-color: #404041;
}

.progressloader .white-box h3 {
  color: #f7fafc;
}

.progressloader .white-box span {
  color: #e4e7ea;
}

.progressloader .progress {
  background-color: rgba(120, 130, 140, 0.9);
}
// Main color swatch

@topbar: #404041;
@sidebar: #fff;
@bodycolor: #f1f2f7;
@headingtext: #2b2b2b;
@bodytext: #686868;
@sidebar-text: #54667a;
@themecolor: #f15925;
@dark-themecolor: #802e13;

@danger: #ff4444;
@success: #00C851;
@warning: #ffbb33;
@primary: #f15925;
@info: #33b5e5;
@muted: #98a6ad;
@dark: #2b2b2b;
@inverse: #4c5667;
@light: #e4e7ea;
@extralight: #f7fafc;

@white: #ffffff;
@red: #fb3a3a;
@purple: #f15925;
@blue: #02bec9;

@border: rgba(120, 130, 140, 0.65);

body {
  background: @sidebar;
}

/*Top Header Part*/

.logo i {
  color: @white;
}

.top-left-part {
  .light-logo {
    display: inline-block;
  }
  .dark-logo {
    display: none;
  }
}

.navbar-header {
  background: @topbar;
}

.navbar-top-links > li > a {
  color: @white;
}

/*Right panel*/
.right-sidebar .rpanel-title {
  background: @themecolor;
}

/*Bread Crumb*/
.bg-title .breadcrumb .active {
  color: @themecolor;
}

.bg-title {

}

/*Sidebar*/

.sidebar {
  background: @sidebar;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  .label-custom {
    background: @megna;
  }
}

#side-menu li a {
  color: @sidebar-text;
}

#side-menu li a {
  color: @sidebar-text;
  border-left: 0px solid @sidebar;
}

#side-menu > li > a {
  &:hover, &:focus {
    background: rgba(0, 0, 0, 0.03);
  }
  &.active {
    border-left: 3px solid @themecolor;
    color: @dark;

    font-weight: 500;
    i {
      color: @themecolor;
    }
  }

}

#side-menu ul > li > a {
  &:hover {
    color: @themecolor;

  }
  &.active {
    color: @dark;
    font-weight: 500;
  }
}

.sidebar #side-menu .user-pro {
  .nav-second-level a:hover {
    color: @themecolor;
  }
}

/*themecolor*/

.bg-theme {
  background-color: @danger !important;
}

.bg-theme-dark {
  background-color: @themecolor !important;
}

/*Chat widget*/
.chat-list .odd .chat-text {
  background: @themecolor;
}

/*Button*/
.btn-custom {
  background: @themecolor;
  border: 1px solid @themecolor;
  color: @white;
  &:hover {
    background: @themecolor;
    opacity: 0.8;
    color: @white;
    border: 1px solid @themecolor;
  }
}

/*Custom tab*/
.customtab li.active a, .customtab li.active a:hover, .customtab li.active a:focus {
  border-bottom: 2px solid @themecolor;
  color: @themecolor;
}

.tabs-vertical li.active a, .tabs-vertical li.active a:hover, .tabs-vertical li.active a:focus {
  background: @themecolor;
  border-right: 2px solid @themecolor;
}

/*Nav-pills*/
.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background: rgba(241, 89, 37, .8);
  color: @white;
}

.nav-pills > li.active > a h5, .nav-pills > li.active > a:focus h5, .nav-pills > li.active > a:hover h5 {
  color: @white;
}
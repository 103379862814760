#weather-widget {
  .weather-container {
    width: 500px;
    background: #fff;
    padding: 5px;
  }

  .weather-container .col-xs-6 {
    text-align: center;
  }

  .weather-container .temp {
    font-size: 18px;
    margin-bottom: 0;
  }

  .weather-container .high {
    font-size: 12px;
  }

  .weather-container .low {
    font-size: 12px;
  }

  .weather-container i.condition {
    font-size: 25px;
    padding-top: 10px;
  }

  .weather-container div.currently i.condition {
    padding-top: 0;
  }

  .weather-container .date {
    background: #33b5e5;
    color: #fff;
    text-transform: uppercase;
  }

  .weather-container div.currently .date {
    background: #f15925;
  }

  .weather-container div.currently .temp {
    font-size: 32px;
    margin-bottom: -10px;
  }

  .weather-container .weather-row {
    margin-bottom: 5px;
  }

  .weather-container .header-row {
    margin-bottom: 5px;
  }

  .weather-container .header-row .time {
    background: #98a6ad;
    color: #fff;
    font-size: 12px;
    line-height: 30px;
  }

  .weather-container .header-row .input-group-addon {
    border-top: 0;
    border-right: 0;
    background: transparent;
  }

  .weather-container #weather-refresh {
    cursor: pointer;
  }

  .weather-container .error-row .alert {
    font-size: 12px;
    padding: 5px;
    margin-bottom: 5px;
  }
}
#app-invoices {
  .invoice-title {
    margin: 0 0 3px 0 !important;
  }

  .line-title {
    margin: 0 0 3px 0 !important;
    font-size: 18px !important;
  }

  [data-dz-remove] {
    cursor: pointer !important;
  }

  .datepicker {
    margin-top: 70px !important;
  }

  tr[data-invoice-id] {
    cursor: pointer;
  }

  #invoice_display .modal-dialog {
    width: 90%;
    margin: 0;
    padding: 0;
  }

  #invoice_display .modal-content {
    height: auto;
    min-height: 90%;
    left: 5%;
    margin: 70px 0;
    border-radius: 0;
  }
}
.calendar-widget table {
  width: 100%;
}

.calendar-widget .heading {
  text-align: center;
  background-color: #f15925;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 10px;
}

.calendar-widget .heading i.next, .calendar-widget .heading i.previous {
  cursor: pointer;
  padding: 10px;
}

.calendar-widget .heading .left {
  float: left;
  position: relative;
  display: inline-block;
}

.calendar-widget .heading .right {
  float: right;
  position: relative;
  display: inline-block;
}

.calendar-widget .heading .month {
  position: relative;
  display: inline-block;
  padding: 7px;
}

.calendar-widget .weekday {
  background-color: #ccc;
}

.calendar-widget .weekday td {
  width: 35px;
  text-align: center;
  font-weight: 400;
  padding: 5px 0;
}

.calendar-widget .days .day {
  width: 35px;
  text-align: center;
  background-color: #ddd;
  padding: 3px 0;
  color: #222;
  font-weight: 200;
}

.calendar-widget .days .day.inactive {
  color: #555;
}

.calendar-widget .days .day.active {
  background-color: #f15925;
  color: #fff;
  font-weight: 500;
}

#top-nav .dropdown.calendar .dropdown-menu {
  padding: 5px;
}

.calendar-widget .settings .form-control {
  height: 30px;
}

.calendar-widget .settings .calendar {
  margin-top: 3px;
}

.calendar-widget .settings .submit {
  text-align: center;
}

.calendar-widget .settings {
  background-color: #efefef;
  display: none;
}

.calendar-widget .settings td {
  padding: 5px 0;
}

.calendar-widget .settings-trigger {
  cursor: pointer;
}
// Variables
;
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700;Roboto+Mono);

@basefont1: 'Poppins', sans-serif;
@basefont2: 'Poppins', sans-serif;

/*Theme Colors*/
@topbar: #3c4451;
@sidebar: #f6f9fb;
@bodycolor: #edf1f5;
@headingtext: #2b2b2b;
@bodytext: #686868;
@sidebar-text: #54667a;
@themecolor: #ff6849;
@dark-themecolor: #4F5467;

/*bootstrap Color*/
@danger: #fb9678;
@success: #00c292;
@warning: #fec107;
@primary: #ab8ce4;
@info: #03a9f3;
@muted: #98a6ad;
@dark: #2b2b2b;
@inverse: #4c5667;
@light: #e4e7ea;
@extralight: #f7fafc;

/*Normal Color*/
@white: #ffffff;
@red: #fb3a3a;
@purple: #9675ce;
@blue: #02bec9;

@border: rgba(120, 130, 140, 0.13);
@megna: #01c0c8;

@rgt: right;
@lft: left;

@dark-text: #848a96;
/*Border radius*/
@radius: 0px;

/*Preloader*/
.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
  .cssload-speeding-wheel {
    position: absolute;
    top: ~"calc(50% - 3.5px)";
    left: ~"calc(50% - 3.5px)";
  }
}

/* This is for popins font for firefox */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v1/2fCJtbhSlhNNa6S2xlh9GyEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
  unicode-range: U+02BC, U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200B-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* This is for popins font for firefox */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v1/UGh2YG8gx86rRGiAZYIbVyEAvth_LlrfE80CYdSH47w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

/* This is for popins font for firefox */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poppins/v1/yQWaOD4iNU5NTY0apN-qj_k_vArhqVIZ0nv9q090hN8.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}


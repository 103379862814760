/*
 * Eliteadmin uses Select 3. These are overrides to style Select 4 properly.
 */

.select2-container {
  .select2-selection__choice {
    background-image: none !important;
    border: none !important;
    height: auto !important;
    padding: 0px !important;
    line-height: 22px !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border-bottom: 1px dashed @border !important;

    .select2-arrow {
      background-image: none !important;
      background: transparent;
      border: none;
      width: 14px;
      top: -2px;
    }
  }
  .select2-container-multi.form-control {
    height: auto;
  }

  width: 100% !important;
}

//.select2-search__field{
//  width: 100% !important;
//}

.select2-selection {
  border: none !important;
  border-bottom: 1px solid @border !important;
  border-radius: 0 !important;
  min-height: 38px !important;
}

.select2-results .select2-highlighted {
  color: #ffffff;
  background-color: @info;
}

.select2-drop-active {
  border: 1px solid #e3e3e3 !important;
  padding-top: 5px;

}

.select2-search {
  input {
    border: 1px solid @border;
  }
}

.select2-container-multi {
  width: 100%;

  .select2-choices {
    border: 1px solid @border !important;
    box-shadow: none !important;
    background-image: none !important;
    border-radius: @radius !important;
    min-height: 38px;

    .select2-search-choice {
      padding: 4px 7px 4px 18px;
      margin: 5px 0 3px 5px;
      color: #555555;
      background: #f5f5f5;
      border-color: @border;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .select2-search-field {
      input {
        padding: 7px 7px 7px 10px;
        font-family: inherit;
      }
    }
  }
}
#app-agency-logo-form {
  img#horizontal-logo, img#vertical-logo, img#square-logo {
    max-width: 100%; /* This rule is very important, please do not ignore this! */
    height: 365px;
  }

  .cropper-container img.empty {
    background-color: #eee;
  }
}

#app-user-avatar-form {
  img#avatar {
    max-width: 100%; /* This rule is very important, please do not ignore this! */
    height: 100px;
  }

  .cropper-container img.empty {
    background-color: #eee;
  }
}
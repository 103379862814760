#brands-products-form{
  .project_container a {
    color: #000; }

  .clearfix:before, .clearfix:after {
    display: block;
    content: "";
    line-height: 0;
    clear: both; }

  .taggle_list {
    float: left;
    width: 100%;
    border-bottom: 0.5px solid #ccc;
  }

  .taggle_input {
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 300;
  }

  .taggle_list li {
    float: left;
    display: inline-block;
    white-space: nowrap;
    font-weight: 300;
    margin-bottom: 1px;
    margin-left: -40px;
  }

  .taggle_text {
    padding-right: 5px;
  }

  .taggle_list .taggle {
    margin-right: 40px;
    background: transparent;
    padding: 3px 7px;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    transition: all .3s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    border-bottom: 1px dashed grey;
  }

  .taggle_list .taggle_hot {
    background: #cac8c4;
  }

  .taggle_list .taggle .close {
    font-size: 1.7rem;
    position: absolute;
    top: 7px;
    right: 0px;
    text-decoration: none;
    padding-left: 2px;
    padding-top: 3px;
    line-height: 0.5;
    color: dimgrey;
    padding-bottom: 4px;
    border: 0;
    background: none;
    cursor: pointer; }

  .taggle_list .taggle:hover {
    background: transparent;
  }

  .taggle_list .taggle:hover > .close {
    display: block;
  }

  .taggle_list .taggle .close:hover {
    color: #f15925;
  }

  .taggle_placeholder {
    position: absolute;
    color: #CCC;
    top: 12px;
    left: 8px;
    transition: opacity, .25s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .taggle_input {
    padding: 8px;
    padding-left: 0;
    float: left;
    margin-top: -5px;
    background: none;
    width: 100%;
    max-width: 100%;
    font-size: 14px;
    margin-left: 5px;
  }

  .taggle_sizer {
    padding: 0;
    margin: -10px;
    position: absolute;
    top: -500px;
    z-index: -1;
    visibility: hidden; }

  /*container styles*/
  .textarea.input {
    border: 0;
    background: transparent;
    min-height: 60px;
    padding: 8px;
    border-radius: 3px;
    color: #555;
    transition: all .25s;
    cursor: text;
    margin-bottom: 10px;
    position: relative;
  }

  .textarea.tags {
    position: relative; }

  .textarea.tags * {
    box-sizing: content-box; }

  .placeholder_input {
    position: relative; }

  .placeholder_input span {
    position: absolute;
    color: #AAA;
    top: 50%;
    margin-top: -11px;
    left: 10px; }

  .placeholder_input input {
    width: 120px; }

  .custom.textarea .taggle {
    border-radius: 0;
    padding: 1px;
    padding-right: 20px;
  }
  .custom.textarea .taggle .close {
    display: inline-block;
    right: 3px;
    color: grey;
  }
}



/************For Laptop (1549px)************/

@media (min-width: 1549px) {
  #page-wrapper {
    margin-right: 240px;
  }
}

/************For Laptop (1280px)************/

@media (max-width: 1350px) {
  .carousel .item {
    h3 {
      font-size: 17px;
      height: 90px;
    }
  }

  .inbox-center {
    a {
      width: 400px;
    }
  }
}

/************Small Desktop************/

@media (min-width: 1024px) {
  .app-search .form-control:focus {
    width: 300px;
  }
}

/************Ipad************/

@media (min-width: 768px) {
  .sidebar {
    z-index: 10;
    position: absolute;
    width: 240px;
    height: 100%;
  }

  #page-wrapper {
    margin-left: 300px;
  }

  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    margin-left: auto;
  }

  .mail_listing {
    border-left: 1px solid rgba(120, 130, 140, 0.13);
    padding-left: 20px;
  }

  .inbox-panel {
    padding-right: 20px;
  }

  .top-minus {
    margin-top: -62px;
    float: right;
  }
}

@media (max-width: 1024px) {
  .b-r-none {
    border-right: 0px;
  }

  .carousel-inner h3 {
    height: 90px;
    overflow: hidden;
  }

  .inbox-center {
    a {
      width: 300px;
    }
  }
}

/************Phone************/

@media (max-width: 767px) {
  .navbar-top-links {
    display: inline-block;
    .profile-pic img {
      margin-right: 0px;
    }
  }

  .top-left-part {
    width: 60px;
  }

  .navbar-top-links li:last-child {
    margin-right: 0px;
  }

  .navbar-top-links .dropdown-messages,
  .navbar-top-links .dropdown-tasks,
  .navbar-top-links .dropdown-alerts {
    width: 260px;
  }

  .row-in-br {
    border-right: 0px;
    border-bottom: 1px solid @border;
  }

  .bg-title {
    .breadcrumb {
      float: left;
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }

  /************Timeline************/
  ul.timeline:before {
    left: 40px;
  }

  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
    top: 16px;
    left: 15px;
    margin-left: 0;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
  }

  ul.timeline > li > .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
  }

  .wizard-steps > li {
    display: block;
  }

  .dropdown .mailbox,
  .dropdown .dropdown-tasks {
    left: -94px;
  }

  /************ Start Update 1.5 ************/
  .mega-dropdown-menu {
    height: 340px;
    overflow: auto;
  }

  .left-aside {
    position: relative;
    width: 100%;
    border: 0px;
  }

  .right-aside {
    margin-left: 0px;
  }

  .chat-main-box .chat-left-aside {
    left: -250px;
    transition: 0.5s ease-in;
    background: @white;
    &.open-pnl {
      left: 0px;
    }
    .open-panel {
      display: block;
    }
  }

  .chat-main-box .chat-right-aside {
    margin: 0px;
  }

  /************ Close Update 1.5 ************/
  .blog-news {
    .overlaybg {
      height: 300px;
    }
    .news-content {
      padding: 15px;
      h2 {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .table-responsive.pro-rd {
    border: none;
  }

  .step-register, .login-register, #msform fieldset {
    position: relative;
  }
}

/************small screen Phone************/

@media (max-width: 480px) {
  .vtabs {
    .tabs-vertical {
      width: auto;
    }
  }

  .stat-item {
    padding-right: 0px;
  }

  .login-box {
    width: 100%;
  }

  .pro-content .pro-list-details {
    height: 100px;
    border-right: none;
  }

  .pro-list-info ul.pro-info li {
    padding: 10px 0 10px 0;
  }

  .pro-list-info ul.pro-info {
    padding-left: 0;
  }

  .pro-agent .agent-img {
    padding-top: 3px;
  }

  .pro-agent .agent-name {
    padding: 2px 0 10px 15px;
  }
}
// Mixins
// --------------------------------------------------


// Sizing shortcuts
// -------------------------

.size(@width, @height) {
  width: @width;
  height: @height;
}

.square(@size) {
  .size(@size, @size);
}

.chartloader {
  height: 100%;
  top: 0;
  position: absolute;
  z-index: 99999;
  background: rgba(255, 255, 255, .5);
}

.chartloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

#generate_chart {
  width: 200px;
}

.vendor-button-circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 5px;
}

.vendor-button {
  display: inline-block;
}

.vendor-button.count .vendor-button-circle {
  background: black;
}

.vendor-button.spend .vendor-button-circle {
  background: repeating-linear-gradient(
          45deg,
          black,
          black 2px,
          transparent 2px,
          transparent 4px
  );
}

.vendor-button .vendor-button-text {
  color: #333333;
  font-size: 13px;
  font-weight: bold;
  display: inline-block;
}